<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <article class="article-header row g-0">
        <div class="col-12">
          <h5>{{ resourcesview.title }}</h5>
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div class="d-flex align-items-center">
            <span class="icon badge bg-purple" v-if="resourcesview.type == 1">
              {{ resourcesview.type | category }}
            </span>
            <span class="icon badge bg-blue" v-if="resourcesview.type == 2">
              {{ resourcesview.type | category }}
            </span>
            <span class="icon badge bg-pink" v-if="resourcesview.type == 3">
              {{ resourcesview.type | category }}
            </span>
            <span class="icon badge bg-cyan" v-if="resourcesview.type == 4">
              {{ resourcesview.type | category }}
            </span>
            <span class="text-capitalize text-black">{{
              resourcesview.regname
            }}</span>
          </div>
          <div class="ms-sm-auto mt-2 mt-sm-0 pt-1 pt-sm-0">
            <span class="me-2 text-date">
              <dfn
                class="time-zone-mark badge rounded-pill bg-green me-1"
                title="Standard time"
                >ST</dfn
              >
              {{
                resourcesview.timezone_date &&
                resourcesview.timezone_date.timezone_common | dateformat
              }}
            </span>
            <span class="fs-13">
              <i class="material-icons align-self-end me-1">visibility</i>
              {{ resourcesview.cnt | comma }}
            </span>
          </div>
        </div>
      </article>

      <article class="article-attach" v-if="resourcesview.filename">
        <a :href="resourcesview.download_link" class="text-truncate more">
          <i class="material-icons-outlined">file_download</i>
          <span>{{ resourcesview.filename }}</span>
        </a>
      </article>

      <article class="article-body">
        <span v-html="resourcesview.contents"></span>
      </article>

      <article class="article-footer">
        <div class="prev" :class="!next_post.resources_pk && 'no-data'">
          <router-link
            :to="{
              name: 'ResourcesView',
              params: { id: next_post.resources_pk },
            }"
            v-if="next_post.resources_pk"
          >
            <i class="material-icons-outlined"> expand_less </i>
            <span>{{ next_post.title || $t("text.support.no_prev") }}</span>
          </router-link>
          <a @click="_alert($t('text.support.no_prev'))" v-else class="pointer">
            <i class="material-icons-outlined"> expand_less </i>
            <span>{{ $t("text.support.no_prev") }}</span>
          </a>
        </div>
        <div class="next" :class="!prev_post.resources_pk && 'no-data'">
          <router-link
            :to="{
              name: 'ResourcesView',
              params: { id: prev_post.resources_pk },
            }"
            v-if="prev_post.resources_pk"
          >
            <i class="material-icons-outlined"> expand_more </i>
            <span>{{ prev_post.title }}</span>
          </router-link>
          <a @click="_alert($t('text.support.no_next'))" v-else class="pointer">
            <i class="material-icons-outlined"> expand_more </i>
            <span>{{ $t("text.support.no_next") }}</span>
          </a>
        </div>
      </article>
      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'Resources',
              query: {
                searchtype: $route.query.searchtype,
                searchname: $route.query.searchname,
              },
            })
          "
        >
          {{ $t("button.common.list") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/support";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "ResourcesView",
  components: {
    location,
  },
  data() {
    return {
      param: {
        searchtype: this.$route.query.searchtype || 1,
        searchname: this.$route.query.searchname || "",
        resourcesno: this.$route.params.id,
      },

      resourcesview: {
        title: "",
        date: "",
        cnt: "",
        contents: "",
        path: "",
        filename: "",
        prevno: "",
        prevtitle: "",
        nextno: "",
        nexttitle: "",
      },
      next_post: {},
      prev_post: {},
    };
  },
  methods: {
    get_resourcesview() {
      ax.get_resourcesview(this.param, (flag, data) => {
        if (flag) {
          this.resourcesview = data.resourcesview;
          this.next_post = data.next_post;
          this.prev_post = data.prev_post;
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_resourcesview();
  },
  watch: {
    "$route.params": function () {
      this.param.resourcesno = parseInt(this.$route.params.id);
      this.get_resourcesview();
    },
  },
  mixins: [myMixin],
};
</script>