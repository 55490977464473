var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('location'),_c('div',{staticClass:"page wrap-1200"},[_c('article',{staticClass:"article-header row g-0"},[_c('div',{staticClass:"col-12"},[_c('h5',[_vm._v(_vm._s(_vm.noticeview.title))])]),_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.noticeview.type === '1')?_c('span',{staticClass:"icon badge bg-purple"},[_vm._v(" "+_vm._s(_vm.$t("title.support.notice"))+" ")]):_vm._e(),(_vm.noticeview.type === '2')?_c('span',{staticClass:"icon badge bg-blue"},[_vm._v(" "+_vm._s(_vm.$t("title.support.news"))+" ")]):_vm._e(),_c('span',{staticClass:"text-capitalize text-black"},[_vm._v(_vm._s(_vm.noticeview.regname))])]),_c('div',{staticClass:"ms-sm-auto mt-2 mt-sm-0 pt-1 pt-sm-0"},[_c('span',{staticClass:"me-2 text-date"},[_c('dfn',{staticClass:"time-zone-mark badge rounded-pill bg-green me-1",attrs:{"title":"Standard time"}},[_vm._v("ST")]),_vm._v(" "+_vm._s(_vm._f("dateformat")(_vm.noticeview.timezone_date && _vm.noticeview.timezone_date.timezone_common))+" ")]),_c('span',{staticClass:"fs-13"},[_c('i',{staticClass:"material-icons align-self-end me-1"},[_vm._v("visibility")]),_vm._v(" "+_vm._s(_vm._f("comma")(_vm.noticeview.cnt))+" ")])])])]),(_vm.noticeview.filename)?_c('article',{staticClass:"article-attach"},[_c('a',{staticClass:"text-truncate",attrs:{"href":_vm.noticeview.download_link}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("file_download")]),_c('span',[_vm._v(_vm._s(_vm.noticeview.originname))])])]):_vm._e(),_c('article',{staticClass:"article-body"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.noticeview.contents)}})]),_c('article',{staticClass:"article-footer"},[_c('div',{staticClass:"prev",class:!_vm.next_post.notice_web_pk && 'no-data'},[(_vm.next_post.notice_web_pk)?_c('router-link',{attrs:{"to":{
            name: 'NoticeView',
            params: { id: _vm.next_post.notice_web_pk },
          }}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" expand_less ")]),_c('span',[_vm._v(_vm._s(_vm.next_post.title))])]):_c('a',{staticClass:"pointer",on:{"click":function($event){_vm._alert(_vm.$t('text.support.no_prev'))}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" expand_less ")]),_c('span',[_vm._v(_vm._s(_vm.$t("text.support.no_prev")))])])],1),_c('div',{staticClass:"next",class:!_vm.prev_post.notice_web_pk && 'no-data'},[(_vm.prev_post.notice_web_pk)?_c('router-link',{attrs:{"to":{
            name: 'NoticeView',
            params: { id: _vm.prev_post.notice_web_pk },
          }}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" expand_more ")]),_c('span',[_vm._v(_vm._s(_vm.prev_post.title))])]):_c('a',{staticClass:"pointer",on:{"click":function($event){_vm._alert(_vm.$t('text.support.no_next'))}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" expand_more ")]),_c('span',[_vm._v(_vm._s(_vm.$t("text.support.no_next")))])])],1)]),_c('div',{staticClass:"btn-bottom-wrapper"},[_c('b-button',{attrs:{"size":"xl","variant":"outline-primary","aria-label":_vm.$t('button.common.list')},on:{"click":function($event){return _vm.$router.push({
            name: 'Notice',
            query: {
              searchtype: _vm.$route.query.searchtype,
              searchname: _vm.$route.query.searchname,
            },
          })}}},[_vm._v(" "+_vm._s(_vm.$t("button.common.list"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }